import http from '../utils/request'

interface feedbackInit {
    user_id: number,
    email?: string
    type: string
    desc: string
}
export const feedback = (data: feedbackInit) => {
    return http.request({
        url: '/other/user/feedback',
        method: 'POST',
        data
    })
}

export const serviceQr = () => {
    return http.request({
        url: '/other/service/qr',
        method: 'GET'
    })
}
